import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../components/layout';
import { rhythm, scale } from '../utils/typography';

const BlogPostTemplate = props => {
  const { pageContext, location } = props;
  const post = get(props, 'data.markdownRemark');
  const siteTitle = get(props, 'data.site.siteMetadata.title');
  const siteDescription = post.excerpt;
  const { previous, next } = pageContext;

  return (
    <Layout location={location}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={`${post.frontmatter.title} | ${siteTitle}`}
      />
      <h1 className="blue-headers">{post.frontmatter.title}</h1>
      <p
        style={{
          ...scale(-1 / 5),
          display: 'block',
          marginBottom: rhythm(1),
        }}
      >
        {post.frontmatter.date}
      </p>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />

      <ul
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          listStyle: 'none',
          padding: 0,
          marginLeft: 0,
          marginBottom: '0.5rem',
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              {previous.frontmatter.title}
            </Link>
          )}
        </li>

        {next && (
          <li>
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title}
            </Link>
          </li>
        )}
      </ul>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  pageContext: PropTypes.shape({
    next: PropTypes.any,
    previous: PropTypes.any,
  }),
};

// Specifies the default values for props:
BlogPostTemplate.defaultProps = {
  pageContext: {
    next: false,
    previous: false,
  },
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
